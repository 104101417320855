const cachios = require('cachios');
const axios = require('axios');

let url = (location.hostname === 'localhost') ? 'http://localhost:3000/' : 'https://app.tennisventures.net:3000/'

const axiosInstance = axios.create({
    baseURL: url
});

import axiosRetry from 'axios-retry';

axiosRetry(axiosInstance, {
    retries: 10,
    retryDelay: axiosRetry.exponentialDelay
});

// all requests will now use this axios instance
const client = cachios.create(axiosInstance, {
    stdTTL: 120
});

export {client, axiosInstance, url};
