<template>
    <section class="content">
        <div class="content mt-6 mb-6" v-if="!participants">
            <h5 class="has-text-grey"><i class="fal fa-tennis-ball fa-spin mr-3"></i>Loading travelers.  Please wait...</h5>
        </div>
        <div class="columns is-multiline is-mobile" v-else>
            <div class="column is-3-widescreen is-4-tablet is-12-mobile" v-for="p in participants">
                <participant :participant="p" @click="gotoTraveler(p)"/>
            </div>
        </div>
    </section>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";
    import async from "async";
    import {client as http} from "../../http_client";
    import Participant from "../../components/Participant";

    export default {
        components: {Participant},
        data: function() {
            return {
                participants: null,
            };
        },
        computed: {
            ...mapGetters(['trip', 'registration']),
        },
        methods: {
            load: function(force) {
                async.series([
                    (cb) => {
                        if (this.registration.id) {
                            http.get('/app/trips/' + this.registration.trip_id + '/participants', {force}).then(response => {
                                this.participants = response.data;
                                cb();
                            });
                        }
                    }
                ], err => {

                });
            },
            gotoTraveler: function(p) {
                this.$router.push({name: 'Traveler', params: {id: p.id}});
            }
        },
        watch: {
            registration: function() {
                this.load(true);
            }
        },
        mounted() {
            this.load();
        }
    };
</script>

<style lang="scss" scoped>
    .profile-photo {
        margin: 0.05px 0.05px 1rem;
        background-color: white;
        border-radius: 0.3rem;
        padding: 1rem;
        cursor: pointer;
        text-align: center;
        font-size: 0.9rem;
        line-height: 1rem;

        i {
            font-size: 75px;
            color: #bebebe;
            display: block;
            margin: 0.8rem;
        }

        @media only screen and (max-width: 768px) {
            h4 {
                font-size: 1rem;
            }
        }

        .meta {
            font-size: 0.85rem;
            color: #4a4a4a;
        }

        .photo-frame {
            border: 1px solid #e7e7e7;
            display: inline-block;
            border-radius: 0.4rem;
            width: 150px;
            height: 150px;

            i {
                padding: 1.8rem 0;
            }
        }
    }
</style>
