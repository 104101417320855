<template>
    <div>
        <h6>{{event.title}}</h6>

        <div class="has-text-weight-bold">{{event.flight.depart_airport.name}} ({{event.flight.depart_airport.iata}})</div>
        <div>{{departLocal}} - {{event.flight.depart_airport.city}}, {{event.flight.depart_airport.country}}</div>

        <div class="mt-1 mb-1 is-size-7"><i class="far fa-ellipsis-h"></i><i class="fas fa-plane ml-2 mr-2"></i><b-tag>{{duration}}</b-tag></div>

        <div class="has-text-weight-bold">{{event.flight.arrive_airport.name}} ({{event.flight.arrive_airport.iata}})</div>
        <div>{{arriveLocal}} - {{event.flight.arrive_airport.city}}, {{event.flight.arrive_airport.country}}</div>
    </div>
</template>

<script>
    import moment from "moment-timezone";

    export default {
        name: 'FlightEvent',
        components: {},
        props: {
            event: {
                type: Object,
                required: true
            },
        },
        data: function () {
            return {};
        },
        computed: {
            duration: function () {
                if (this.event.flight.arrive_at && this.event.flight.depart_at) {
                    let min = moment.duration(moment(this.event.flight.arrive_at).diff(this.event.flight.depart_at)).asMinutes();
                    let hours = Math.floor(min / 60);
                    let minutes = min % 60;
                    if (hours > 0) {
                        return hours + ' hour' + (hours !== 1 ? 's' : '') + ', ' + minutes + ' minute' + (minutes !== 1 ? 's' : '');
                    } else {
                        return minutes + ' minutes';
                    }
                }
            },
            departLocal: function () {
                if (this.event.flight.depart_at)
                    return moment.utc(this.event.flight.depart_at).tz(this.event.flight.depart_airport.tz_database).format('MMM Do, YYYY, h:mm A');
            },
            arriveLocal: function () {
                if (this.event.flight.arrive_at)
                    return moment.utc(this.event.flight.arrive_at).tz(this.event.flight.arrive_airport.tz_database).format('MMM Do, YYYY, h:mm A');
            },
            departLocalTime: function () {
                if (this.event.flight.depart_at)
                    return moment.utc(this.event.flight.depart_at).tz(this.event.flight.depart_airport.tz_database).format('h:mm A');
            },
            arriveLocalTime: function () {
                if (this.event.flight.arrive_at)
                    return moment.utc(this.event.flight.arrive_at).tz(this.event.flight.arrive_airport.tz_database).format('h:mm A');
            }
        }
    };
</script>

<style lang="scss" scoped>

    //li {
    //    color: darken($t-primary, 10);
    //
    //    .flight-title {
    //        font-size: .9rem;
    //        font-weight: 700;
    //    }
    //
    //    padding: 0.5rem 0;
    //
    //    .user-details {
    //        margin-top: 0.5rem;
    //    }
    //}

    h6 {
        font-size: 1rem;
    }
</style>
