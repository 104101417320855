import axios from 'axios';
import {client as http, axiosInstance} from '../../http_client';

export default {
    state: {
        currentUser: (localStorage.getItem('user') != null) ? JSON.parse(localStorage.getItem('user')) : null,
        token: (localStorage.getItem('token') != null) ? localStorage.getItem('token') : null,
    },
    getters: {
        currentUser: state => state.currentUser,
        token: state => state.token,
    },
    mutations: {
        setUser(state, payload) {
            state.currentUser = payload;
            state.token = payload.token;
            state.processing = false;
        },
        setLogout(state) {
            state.currentUser = null;
            state.processing = false;
        }
    },
    actions: {
        login({commit}, payload) {
            return new Promise((resolve, reject) => {
                http.post('/login/', payload).then(response => {
                    response.data.user.token = response.data.token;
                    localStorage.setItem('token', response.data.token);
                    localStorage.setItem('user', JSON.stringify(response.data.user));

                    console.log('Setting Authorization Header', response.data.token);
                    axiosInstance.defaults.headers.common['Authorization'] = response.data.token;

                    commit('setUser', response.data.user);
                    resolve(response.data);
                }, err => {
                    localStorage.removeItem('token');
                    reject(err.response.data);
                });
            });
        },
        signOut({commit}) {
            localStorage.removeItem('token');
            localStorage.removeItem('user');
            localStorage.removeItem('registration');
            commit('setLogout');
        }
    }
};
