<template>
    <div class="print-frame content">

        <div v-if="!flights" class="p-5">
            <h5 class="has-text-grey"><i class="fal fa-tennis-ball fa-spin mr-3"></i>Loading your details.  Please wait...</h5>

        </div>
        <div class="content" v-else>
            <div class="has-text-centered">
                <img src="@/assets/logo.svg" alt="Tennis Ventures" class="logo">
                <h3 class="mb-1">{{ trip.title }}</h3>
                <div class="is-size-6">{{ trip.start_on | moment('MMMM, Do, YYYY') }} - {{ trip.end_on | moment('MMMM, Do, YYYY') }}</div>
                <div class="is-size-5">
                    {{ registration.user.first_name }} {{ registration.user.last_name }}<span v-if="registration.second_id">,
                                {{ registration.second_user.first_name }} {{ registration.second_user.last_name }}
                </span>
                </div>
                <div class="mt-3 is-size-7"><i class="fas fa-info-circle mr-2"></i>As of {{now | moment('MMM Do, YYYY')}}. Details are subject to change.<br>Use Tennis Ventures Navigator (https://app.tennisventures.net) for up-to-date information.</div>

            </div>

            <hr/>

            <h3 class="mt-6 mb-5 ml-2"><i class="fal fa-plane fa-fw mr-3"></i>Flights</h3>

            <div>
                <flight :flight="flight" v-for="flight in flights" v-if="flights.length" print/>
                <h5 class="has-text-grey" v-else><i class="fal fa-plane mr-3"></i>You currently have no flight information. Please check back later.</h5>
            </div>

            <h3 class="mt-6 mb-5 ml-2"><i class="fal fa-calendar-alt fa-fw mr-3"></i>Trip Itinerary</h3>

            <div>
                <itinerary-day :day="day" v-for="day in itinerary" print/>
            </div>
        </div>

    </div>
</template>

<script>
    import ItineraryDay from "../../components/ItineraryDay";
    import Flight from "../../components/Flight";
    import DateLabel from "../../components/DateLabel";
    import async from "async";
    import {client as http} from "../../http_client";

    export default {
        components: {ItineraryDay, DateLabel, Flight},
        props: ['id'],
        data: function() {
            return {
                registration: null,
                trip: null,
                itinerary: null,
                flights: null
            };
        },
        computed: {
            now: () => new Date()
        },
        methods: {
            loadItinerary: function(force) {
                async.series([
                    (cb) => {
                        http.get('/app/registrations/' + this.id).then(response => {
                            this.registration = response.data;
                            cb();
                        });
                    },
                    (cb) => {
                        http.get('/app/trips/' + this.registration.trip_id).then(response => {
                            this.trip = response.data;
                            cb();
                        });
                    },
                    (cb) => {
                        http.get('/app/registrations/' + this.registration.id + '/itinerary', {force}).then(response => {
                            this.itinerary = response.data;
                            cb();
                        });
                    },
                    (cb) => {
                        http.get('/app/registrations/' + this.registration.id + '/flights', {force}).then(response => {
                            this.flights = response.data;
                            cb();
                        });
                    }
                ], err => {

                });
            }
        },
        mounted() {
            this.loadItinerary();
        }
    };
</script>

<style lang="scss" scoped>
    .print-frame {
        margin: 0;
        padding: 0;
        clear: both;
        page-break-after: always;

        .logo {
            height: 180px;
        }

        hr {
            border-bottom: 1px solid #aaa;
        }
    }
</style>
