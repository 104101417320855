<template>
    <div class="columns is-multiline is-mobile day-container" :class="{'details': details || print, 'print': print}" @click="details=true">
        <div class="column is-narrow">
            <date-label :date="day.day_on" :print="print"/>
        </div>
        <div class="column content">
            <div v-if="details || print">
                <img class="day-photo" :src="'https://app.tennisventures.net:3000/api/day_photos/img/' + day.photos[0].file + '/day'" v-if="day.photos && day.photos[0]"/>
                <h4 class="pt-1">{{ day.title }}</h4>
                <div class="mb-5" style="color: #525252">{{day.description}}</div>

                <div class="mb-6" v-if="!print"><b-button type="is-primary" outlined @click.stop="details=false"><i class="far fa-magnifying-glass-minus mr-2"></i>Hide Details</b-button></div>
                <div v-for="event in day.events" class="event" v-if="event.active !== '0'">
                    <div v-if="event.type==='override_event'">
                        <h6>{{event.day_event.title}}</h6>
                        <div class="meta" v-if="!event.day_event.time && event.day_event.start_at && event.day_event.end_at"><i class="far fa-clock fa-fw mr-2"></i>{{event.day_event.start_at | moment('timezone', event.day_event.start_tz, 'h:mm A')}} - {{event.day_event.end_at | moment('timezone', event.day_event.end_tz, 'h:mm A')}}</div>
                        <div class="meta" v-if="event.day_event.time"><i class="far fa-clock fa-fw mr-2"></i>{{event.day_event.time}}</div>
                        <div class="meta"><i class="far fa-map-marker fa-fw mr-2"></i>{{event.day_event.location}}</div>
                    </div>
                    <div v-else>
                        <h6>{{ event.title }}</h6>
                        <div class="mb-4 mr-6 critical" v-if="event.critical_info">{{event.critical_info}}</div>
                        <div v-if="event.description" class="mb-4 mr-6">{{event.description}}</div>
                        <div v-if="!event.time && event.start_at && event.end_at"><i class="far fa-clock fa-fw mr-2"></i>{{ event.start_at | moment('timezone', event.start_tz, 'h:mm A') }} - {{ event.end_at | moment('timezone', event.end_tz, 'h:mm A') }}</div>
                        <div v-if="event.time"><i class="far fa-clock fa-fw mr-2"></i>{{ event.time }}</div>
                        <div class="meta" v-if="event.location"><i class="far fa-map-marker fa-fw mr-2"></i>{{ event.location }}</div>
                    </div>
                    <flight-event :event="event" v-else-if="event.type==='flight'"/>
                </div>
            </div>
            <div v-else>
                <img :src="'https://app.tennisventures.net:3000/api/day_photos/img/' + day.photos[0].file + '/day'" class="preview is-pulled-left mb-0 mr-5" style="height: 68px" v-if="day.photos && day.photos[0]"/>
                <div v-else class="photo-frame is-pulled-left mb-0 mr-5"><i class="fat fa-calendar-day"></i></div>

                <h4 class="pt-1 mb-2">{{ day.title }}</h4>
                <div class="meta"><i class="fas fa-search mr-2"></i>{{count}} Event<span v-if="count !== 1">s</span></div>
            </div>
        </div>
    </div>
</template>

<script>
    import DateLabel from "./DateLabel";
    import FlightEvent from "./FlightEvent";

    export default {
        name: "ItineraryDay",
        components: {FlightEvent, DateLabel},
        props: {
            day: {
                required: true
            },
            print: {
                type: Boolean,
                default: false
            }
        },
        data: function() {
            return {
                details: false
            }
        },
        computed: {
            count: function() {
                let result = 0;
                for (let i = 0; i < this.day.events.length; i++) {
                    const evt = this.day.events[i];
                    if (evt.active !== '0') result++;
                }
                return result;
            }
        }
    };
</script>

<style lang="scss" scoped>
    .day-container {
        margin: 0.05px 0.05px 1rem;
        background-color: white;
        border-radius: 0.3rem;
        position: relative;
        padding: 0.05px;
        cursor: pointer;

        &.details {
            margin-bottom: 3rem;
            cursor: default;
        }

        @media only screen and (max-width: 768px) {
            h4 {
                font-size: 1rem;
            }
        }

        &.print {
            .day-photo {
                max-width: 200px;
            }
        }
    }

    img {
        border-radius: 0.3rem;
        margin-bottom: 1rem;

        &.preview {
            @media only screen and (max-width: 500px) {
                display: none;
            }
        }

    }

    .photo-frame {
        border-radius: 0.3rem;
        margin-bottom: 1rem;
        border: 1px solid #eee;
        width: 102px;
        height: 68px;
        text-align:  center;

        i {
            color: #eee;
            font-size: 2.6rem;
            margin-top: 13px;
        }

        @media only screen and (max-width: 500px) {
            display: none;
        }
    }

    .event {
        font-size: 0.85rem;
        border-top: 2px solid #eee;
        padding: 1rem 0;

        h6 {
            font-size: 1rem;
        }

        .critical {
            border: 1px solid red;
            background-color: #fae8e8;
            border-radius: 8px;
            padding: 1rem 1.2rem;
        }
    }

    .meta {
        font-size: 0.85rem;
        color: #424242;
    }
</style>
