<template>
    <div class="content">
        <div class="columns">
            <div class="column">
                <div class="white-container pt-6 pb-6 has-text-centered">
                    <h4 class="has-text-primary mb-5">Be There. Stay There. Play There.</h4>

                    <div v-if="trip && trip.start_on">
                        <flip-countdown :deadline="trip.start_on"></flip-countdown>
                    </div>
                </div>
                <div class="white-container mt-5" v-if="trip.app_today" v-html="trip.app_today"></div>
            </div>
            <div class="column is-4">
                <div class="white-container pt-6">
                    <h4 class="mb-4"><i class="far fa-clipboard-check fa-fw mr-2 has-text-primary"></i>Pre-Travel Tasks</h4>

                    <!--                    <router-link :to="{name: 'Preferences'}">-->
                    <!--                        <div class="task-menu-item">-->
                    <!--                            <div class="columns is-gapless">-->
                    <!--                                <div class="column is-narrow pb-0">-->
                    <!--                                    <i class="far fa-fw fa-tasks mr-2 has-text-primary"></i>-->
                    <!--                                </div>-->
                    <!--                                <div class="column pb-0">-->
                    <!--                                    <strong class="has-text-black">Review your Trip Preferences</strong>-->
                    <!--                                    <div class="is-size-7">-->
                    <!--                                        Answer important questions about your trip.-->
                    <!--                                    </div>-->
                    <!--                                </div>-->
                    <!--                            </div>-->
                    <!--                        </div>-->
                    <!--                    </router-link>-->

                    <router-link :to="{name: 'Profile'}">
                        <div class="task-menu-item">
                            <div class="columns is-gapless">
                                <div class="column is-narrow pb-0">
                                    <i class="far fa-fw fa-address-card mr-2 has-text-primary"></i>
                                </div>
                                <div class="column pb-0">
                                    <strong class="has-text-black">Create your Personal Profile</strong>
                                    <div class="is-size-7">
                                        Let fellow travelers know who you are.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </router-link>

                    <router-link :to="{name: 'Travel'}">
                        <div class="task-menu-item">
                            <div class="columns is-gapless">
                                <div class="column is-narrow pb-0">
                                    <i class="fal fa-fw fa-suitcase-rolling mr-2 has-text-primary"></i>
                                </div>
                                <div class="column pb-0">
                                    <strong class="has-text-black">Requirements, Packing & FAQs</strong>
                                    <div class="is-size-7">
                                        Review travel requirements and other info.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </router-link>

                    <router-link :to="{name: 'Itinerary'}">
                        <div class="task-menu-item">
                            <div class="columns is-gapless">
                                <div class="column is-narrow pb-0">
                                    <i class="fal fa-fw fa-tennis-ball mr-2 has-text-primary"></i>
                                </div>
                                <div class="column pb-0">
                                    <strong class="has-text-black">Explore your Trip Itinerary</strong>
                                    <div class="is-size-7">
                                        Check out the exciting events planned.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </router-link>
                </div>

                <ul class="sub-nav-menu mt-5" v-if="currentUser.registrations > 1">
                    <router-link :to="{name: 'Trips'}">
                        <li><i class="far fa-tennis-ball fa-fw mr-3"></i>View a Different Trip</li>
                    </router-link>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
    import FlipCountdown from 'vue2-flip-countdown';
    import {mapGetters} from "vuex";

    export default {
        components: {FlipCountdown},
        data: function() {
            return {};
        },
        computed: {
            ...mapGetters(['currentUser', 'trip', 'registration']),
        },
    };
</script>

<style lang="scss" scoped>
    .task-menu-item {
        padding: 10px;
        cursor: pointer;
        margin: 0 0 5px 25px;

        &:hover {
            border-radius: 5px;
            background-color: #f4f4f4;
        }
    }
</style>

<style lang="scss">
    .flip-card__top,
    .flip-card__bottom,
    .flip-card__back-bottom,
    .flip-card__back::before,
    .flip-card__back::after {
        color: #9ec43e !important;
    }
</style>
