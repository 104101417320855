<template>
    <div class="content">
        <h3 class="mt-2 mb-5 ml-2 is-hidden-desktop"><i class="fal fa-address-card fa-fw mr-3"></i>Profile</h3>

        <div class="content mt-6 mb-6" v-if="!user">
            <h5 class="has-text-grey"><i class="fal fa-tennis-ball fa-spin mr-3"></i>Loading your account. Please wait...</h5>
        </div>

        <div v-else>
            <div v-if="upload">
                <div style="border-radius: 1rem">
                    <cropper
                        ref="cropper"
                        class="cropper"
                        :src="image.src"
                        :stencil-size="{
                            width: 200,
		                    height: 200
                        }"
                        :stencil-props="{
                            handlers: {},
                            movable: false,
                            resizable: false,
                            aspectRatio: 1,
                        }"
                        image-restriction="stencil"
                    />
                </div>

                <div class="mt-6 has-text-right">
                    <b-button @click="upload=false" class="mr-4">Cancel</b-button>
                    <b-button type="is-primary" @click="uploadPhoto" :disabled="processing"><i class="fas fa-check mr-2"></i>Upload Photo</b-button>
                </div>
            </div>
            <div v-else>
                <div class="columns">
                    <div class="column is-narrow has-text-centered">
                        <div class="profile-photo">
                            <div class="photo-frame" v-if="!user.photo_file">
                                <i class="fat fa-user"></i>
                            </div>
                            <div class="photo-frame" v-else>
                                <img :src="apiUrl + 'app/user/photo/' + user.photo_file"/>
                            </div>
                            <div><strong>{{ user.first_name }} {{ user.last_name }}</strong></div>
                            <div>{{ user.city }}, {{ user.state }}</div>
                        </div>
                        <b-button type="is-primary" @click="$refs.file.click()">
                            <i class="fas fa-camera mr-3"></i>Choose Photo
                        </b-button>
                    </div>
                    <div class="column">
                        <div class="white-container content">
                            <h6><i class="far fa-user fa-fw mr-2"></i>Profile Information</h6>
                            <div class="columns">
                                <div class="column">
                                    <b-field label="Travel Companion" label-position="inside">
                                        <b-input v-model="user.travel_companion"/>
                                    </b-field>
                                </div>
                            </div>
                            <div class="columns">
                                <div class="column">
                                    <b-field label="Favorite Tennis Ventures Trip" label-position="inside">
                                        <b-select expanded v-model="user.favorite_trip">
                                            <option :value="t.title" v-for="t in user.trips">{{ t.title }}</option>
                                        </b-select>
                                    </b-field>
                                </div>
                            </div>
                            <div class="columns">
                                <div class="column">
                                    <b-field label="Favorite Professional Tennis Player" label-position="inside">
                                        <b-input v-model="user.favorite_player"/>
                                    </b-field>
                                </div>
                            </div>
                            <div class="columns">
                                <div class="column">
                                    <b-field label="Other Favorite Sports/Activities" label-position="inside">
                                        <b-input v-model="user.favorite_sport"/>
                                    </b-field>
                                </div>
                            </div>
                            <div class="columns">
                                <div class="column">
                                    <b-field label="Tennis Club" label-position="inside">
                                        <b-input v-model="user.tennis_club"/>
                                    </b-field>
                                </div>
                            </div>
                            <div class="columns">
                                <div class="column">
                                    <b-field label="Tennis Pro" label-position="inside">
                                        <b-input v-model="user.tennis_pro"/>
                                    </b-field>
                                </div>
                            </div>



                        </div>
                        <div class="white-container content">
                            <h6 class="mb-5 pb-3"><i class="far fa-address-card fa-fw mr-2"></i>Contact Information</h6>

                            <div class="columns">
                                <div class="column">
                                    <b-field>
                                        <b-checkbox v-model="user.share_email" true-value="1" false-value="0">Share <span class="has-text-primary">{{ user.email }}</span> with others.</b-checkbox>
                                    </b-field>
                                </div>
                            </div>
                            <div class="columns" v-if="user.phone">
                                <div class="column">
                                    <b-field>
                                        <b-checkbox v-model="user.share_phone" true-value="1" false-value="0">Share <span class="has-text-primary">+{{ user.phone}}</span> with others.</b-checkbox>
                                    </b-field>
                                </div>
                            </div>
                            <div class="columns" v-if="user.phone_2">
                                <div class="column">
                                    <b-field>
                                        <b-checkbox v-model="user.share_phone_2" true-value="1" false-value="0">Share <span class="has-text-primary">+{{ user.phone_2}}</span> with others.</b-checkbox>
                                    </b-field>
                                </div>
                            </div>
                            <div class="columns">
                                <div class="column">
                                    <b-field label="Facebook" label-position="inside">
                                        <b-input v-model="user.facebook"/>
                                    </b-field>
                                </div>
                            </div>
                            <div class="columns">
                                <div class="column">
                                    <b-field label="Instagram" label-position="inside">
                                        <b-input v-model="user.instagram"/>
                                    </b-field>
                                </div>
                            </div>
                            <div class="columns">
                                <div class="column">
                                    <b-field label="Twitter" label-position="inside">
                                        <b-input v-model="user.twitter"/>
                                    </b-field>
                                </div>
                            </div>
                            <div class="columns">
                                <div class="column">
                                    <b-field label="WhatsApp" label-position="inside">
                                        <b-input v-model="user.whatsapp"/>
                                    </b-field>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="mt-6 has-text-right">
                    <b-button type="is-primary" @click="save" :disabled="processing"><i class="fas fa-check mr-2"></i>Save Changes</b-button>
                </div>
            </div>
        </div>

        <input type="file" ref="file" @change="loadImage($event)" accept="image/*" class="is-invisible">

    </div>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";
    import async from "async";
    import {client as http, url} from "../../http_client";
    import {Cropper} from 'vue-advanced-cropper';
    import 'vue-advanced-cropper/dist/style.css';

    export default {
        components: {
            Cropper
        },
        data: function() {
            return {
                user: null,
                upload: null,
                processing: false,
                image: {
                    src: null,
                    type: null
                },
                apiUrl: url
            };
        },
        computed: {
            ...mapGetters(['trip', 'registration']),
        },
        methods: {
            loadUser: function(force) {
                http.get('/app/user/', {force: true}).then(response => {
                    this.user = response.data;
                });
            },
            loadImage(event) {
                const {files} = event.target;
                if (files && files[0]) {
                    if (this.image.src) {
                        URL.revokeObjectURL(this.image.src);
                    }
                    const blob = URL.createObjectURL(files[0]);
                    const reader = new FileReader();

                    reader.onload = (e) => {
                        this.image = {
                            src: blob,
                            type: getMimeType(e.target.result, files[0].type),
                        };
                    };
                    reader.readAsArrayBuffer(files[0]);
                    this.upload = true;
                }
            },
            save: function() {
                if (!this.processing) {
                    this.processing = true;
                    http.put('/app/user', this.user, {force: true}).then(response => {
                        this.$notify({
                            group: 'notify',
                            type: 'success',
                            title: 'Save Complete',
                            text: 'Your account settings have been saved.'
                        });
                        setTimeout(() => {
                            this.processing = false;
                        }, 3500);
                    });
                }
            },
            setCompanion: function() {
                if (!this.user.travel_companion && this.registration.second_id) {
                    this.user.travel_companion = this.registration.second_firstname + ' ' + this.registration.second_lastname;
                }
            },
            uploadPhoto: function() {
                const {canvas} = this.$refs.cropper.getResult();

                if (canvas) {
                    const data = new FormData();
                    canvas.toBlob(blob => {

                        data.append('file', blob);

                        fetch(url + 'app/user/photo/upload', {
                            method: 'POST',
                            body: data,
                        }).then(response => response.json()
                        ).then(data => {
                            http.put('/app/user', {
                                photo_file: data.file_name,
                                photo_mime: data.file_mime
                            }, {force: true}).then(response => {
                                this.processing = false;
                                this.upload = false;
                                this.user.photo_file = data.file_name
                                this.user.photo_mime = data.file_mime
                            });
                        });
                    }, this.image.type);
                }
            }
        },
        watch: {
            'registration': function() {
                if (this.user) this.setCompanion();
            },
            'user': function() {
                if (this.registration) this.setCompanion();
            }
        },
        mounted() {
            this.loadUser(true);
        }
    };

    function getMimeType(file, fallback = null) {
        const byteArray = (new Uint8Array(file)).subarray(0, 4);
        let header = '';
        for (let i = 0; i < byteArray.length; i++) {
            header += byteArray[i].toString(16);
        }
        switch (header) {
            case "89504e47":
                return "image/png";
            case "47494638":
                return "image/gif";
            case "ffd8ffe0":
            case "ffd8ffe1":
            case "ffd8ffe2":
            case "ffd8ffe3":
            case "ffd8ffe8":
                return "image/jpeg";
            default:
                return fallback;
        }
    }
</script>

<style lang="scss" scoped>
    .profile-photo {
        margin: 0.05px 0.05px 1rem;
        background-color: white;
        border-radius: 0.3rem;
        padding: 1rem;
        cursor: pointer;
        text-align: center;
        font-size: 0.9rem;
        line-height: 1rem;

        i {
            font-size: 75px;
            color: #bebebe;
            display: block;
            margin: 0.8rem;
        }

        @media only screen and (max-width: 768px) {
            h4 {
                font-size: 1rem;
            }
        }

        .meta {
            font-size: 0.85rem;
            color: #4a4a4a;
        }

        .photo-frame {
            border: 1px solid #e7e7e7;
            display: inline-block;
            border-radius: 0.4rem;
            width: 150px;
            height: 150px;
            margin-bottom: 0.8rem;

            i {
                padding: 1.8rem 0;
            }
        }
    }
</style>

<style lang="scss">
    .cropper {
        height: 400px;
        width: 400px;
    }
</style>
