<template>
    <div class="participant-container" @click="$emit('click')">
        <div class="photo-frame" v-if="!participant.photo_file">
            <i class="fat fa-user"></i>
        </div>
        <div class="photo-frame" v-else>
            <img :src="apiUrl + 'app/user/photo/' + participant.photo_file"/>
        </div>
        <div class="mb-2"><strong>{{participant.first_name}} {{participant.last_name}}</strong></div>
        <div class="mt-1 has-text-primary" v-if="participant.title">{{ participant.title }}</div>
        <div class="mt-1">{{participant.city}}<span v-if="participant.state !== 'NA'">, {{participant.state}}</span><span v-else>, {{participant.country}}</span></div>
    </div>
</template>

<script>
    import {url} from '@/http_client';

    export default {
        name: 'Participant',
        components: {},
        props: {
            participant: {
                type: Object,
                required: true
            }
        },
        data: function () {
            return {
                apiUrl: url
            };
        },
        computed: {

        }
    };
</script>

<style lang="scss" scoped>
    .participant-container {
        margin: 0.05px 0.05px 1rem;
        background-color: white;
        border-radius: 0.3rem;
        padding: 1rem;
        cursor: pointer;
        text-align: center;
        font-size: 0.9rem;
        line-height: 1rem;

        i {
            font-size: 75px;
            color: #bebebe;
            display: block;
            margin: 0.8rem;
        }

        @media only screen and (max-width: 768px) {
            h4 {
                font-size: 1rem;
            }
        }

        .meta {
            font-size: 0.85rem;
            color: #4a4a4a;
        }

        .photo-frame {
            border: 1px solid #e7e7e7;
            display: inline-block;
            border-radius: 0.4rem;
            width: 150px;
            height: 150px;
            margin-bottom: 0.8rem;

            i {
                padding: 1.8rem 0;
            }
        }
    }

</style>
