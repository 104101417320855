<template>
    <section class="content">

        <router-link :to="{name: 'AllTravelers'}"><h4 class="mb-6"><i class="fal fa-arrow-left-long mr-3"></i>Return to all travelers</h4></router-link>

        <div class="content mt-6 mb-6" v-if="!participant">
            <h5 class="has-text-grey"><i class="fal fa-tennis-ball fa-spin mr-3"></i>Loading traveler.  Please wait...</h5>
        </div>
        <div class="columns" v-else>
            <div class="column is-narrow has-text-centered">
                <div class="profile-photo">
                    <div class="photo-frame" v-if="!participant.photo_file">
                        <i class="fat fa-user"></i>
                    </div>
                    <div class="photo-frame" v-else>
                        <img :src="apiUrl + 'app/user/photo/' + participant.photo_file"/>
                    </div>
                </div>
            </div>
            <div class="column">
                <div class="white-container content">
                    <h3 class="mb-2">{{ participant.first_name }} {{ participant.last_name }}</h3>
                    <h6 class="mb-1 has-text-primary" v-if="participant.title">{{ participant.title }}</h6>
                    <div class="mb-5">{{ participant.city }}, {{ participant.state }}</div>

                    <div class="mb-2 columns is-gapless" v-if="participant.travel_companion">
                        <div class="column is-narrow is-mobile"></div>
                        <div class="column is-narrow mr-3">
                            <i class="far fa-user-group fa-fw mr-3"></i><strong>Travel Companion</strong>
                        </div>
                        <div class="column">
                            <i class="far fa-fw mr-3 is-hidden-tablet"></i>{{ participant.travel_companion }}
                        </div>
                    </div>

                    <div class="mb-1 columns is-gapless">
                        <div class="column is-narrow is-mobile"></div>
                        <div class="column">
                            <i class="far fa-plane-departure fa-fw mr-3"></i><strong>Trips with Tennis Ventures</strong>
                        </div>
                    </div>
                    <div class="mb-3">
                        <div v-for="t in participant.trips">
                            <i class="far fa-fw mr-3"></i>{{ t.title }}
                        </div>
                    </div>

                    <div class="mb-2 columns is-gapless" v-if="participant.favorite_trip">
                        <div class="column is-narrow is-mobile"></div>
                        <div class="column is-narrow mr-3">
                            <i class="far fa-plane fa-fw mr-3"></i><strong>Favorite Tennis Ventures Trip</strong>
                        </div>
                        <div class="column">
                            <i class="far fa-fw mr-3 is-hidden-tablet"></i>{{ participant.favorite_trip }}
                        </div>
                    </div>

                    <div class="mb-2 columns is-gapless" v-if="participant.favorite_player">
                        <div class="column is-narrow is-mobile"></div>
                        <div class="column is-narrow mr-3">
                            <i class="far fa-trophy fa-fw mr-3"></i><strong>Favorite Professional Tennis Player</strong>
                        </div>
                        <div class="column">
                            <i class="far fa-fw mr-3 is-hidden-tablet"></i>{{ participant.favorite_player }}
                        </div>
                    </div>

                    <div class="mb-2 columns is-gapless" v-if="participant.tennis_club">
                        <div class="column is-narrow is-mobile"></div>
                        <div class="column is-narrow mr-3">
                            <i class="far fa-tennis-ball fa-fw mr-3"></i><strong>Tennis Club</strong>
                        </div>
                        <div class="column">
                            <i class="far fa-fw mr-3 is-hidden-tablet"></i>{{ participant.tennis_club }}
                        </div>
                    </div>

                    <div class="mb-2 columns is-gapless" v-if="participant.tennis_pro">
                        <div class="column is-narrow is-mobile"></div>
                        <div class="column is-narrow mr-3">
                            <i class="far fa-racquet fa-fw mr-3"></i><strong>Tennis Pro/Coach</strong>
                        </div>
                        <div class="column">
                            <i class="far fa-fw mr-3 is-hidden-tablet"></i>{{ participant.tennis_pro }}
                        </div>
                    </div>

                    <div class="mb-2 columns is-gapless" v-if="participant.favorite_sport">
                        <div class="column is-narrow is-mobile"></div>
                        <div class="column is-narrow mr-3">
                            <i class="far fa-icons fa-fw mr-3"></i><strong>Other Favorite Sports/Activities</strong>
                        </div>
                        <div class="column">
                            <i class="far fa-fw mr-3 is-hidden-tablet"></i>{{ participant.favorite_sport }}
                        </div>
                    </div>

                    <hr/>

                    <div class="mb-2 columns is-gapless" v-if="participant.email">
                        <div class="column is-narrow is-mobile"></div>
                        <div class="column is-narrow mr-3">
                            <i class="far fa-envelope fa-fw mr-3"></i><strong>E-Mail</strong>
                        </div>
                        <div class="column">
                            <i class="far fa-fw mr-3 is-hidden-tablet"></i><a :href="'mailto:' + participant.email">{{ participant.email }}</a>
                        </div>
                    </div>

                    <div class="mb-2 columns is-gapless" v-if="participant.phone">
                        <div class="column is-narrow is-mobile"></div>
                        <div class="column is-narrow mr-3">
                            <i class="far fa-phone fa-fw mr-3"></i><strong>Phone</strong>
                        </div>
                        <div class="column">
                            <i class="far fa-fw mr-3 is-hidden-tablet"></i><a :href="'tel:+' + participant.phone">+{{ participant.phone }}</a>
                        </div>
                    </div>

                    <div class="mb-2 columns is-gapless" v-if="participant.phone_2">
                        <div class="column is-narrow is-mobile"></div>
                        <div class="column is-narrow mr-3">
                            <i class="far fa-phone fa-fw mr-3"></i><strong>Phone</strong>
                        </div>
                        <div class="column">
                            <i class="far fa-fw mr-3 is-hidden-tablet"></i><a :href="'tel:+' + participant.phone_2">+{{ participant.phone_2 }}</a>
                        </div>
                    </div>

                    <div class="mb-2 columns is-gapless" v-if="participant.instagram">
                        <div class="column is-narrow is-mobile"></div>
                        <div class="column is-narrow mr-3">
                            <i class="fab fa-instagram fa-fw mr-3"></i><strong>Instagram</strong>
                        </div>
                        <div class="column">
                            <i class="fal fa-fw mr-3 is-hidden-tablet"></i>{{ participant.instagram }}
                        </div>
                    </div>

                    <div class="mb-2 columns is-gapless" v-if="participant.facebook">
                        <div class="column is-narrow is-mobile"></div>
                        <div class="column is-narrow mr-3">
                            <i class="fab fa-facebook-f fa-fw mr-3"></i><strong>Facebook</strong>
                        </div>
                        <div class="column">
                            <i class="fab fa-fw mr-3 is-hidden-tablet"></i>{{ participant.facebook }}
                        </div>
                    </div>

                    <div class="mb-2 columns is-gapless" v-if="participant.twitter">
                        <div class="column is-narrow is-mobile"></div>
                        <div class="column is-narrow mr-3">
                            <i class="fab fa-twitter fa-fw mr-3"></i><strong>Twitter</strong>
                        </div>
                        <div class="column">
                            <i class="fab fa-fw mr-3 is-hidden-tablet"></i>{{ participant.twitter }}
                        </div>
                    </div>

                    <div class="mb-2 columns is-gapless" v-if="participant.whatsapp">
                        <div class="column is-narrow is-mobile"></div>
                        <div class="column is-narrow mr-3">
                            <i class="fab fa-whatsapp fa-fw mr-3"></i><strong>WhatsApp</strong>
                        </div>
                        <div class="column">
                            <i class="fab fa-fw mr-3 is-hidden-tablet"></i>{{ participant.whatsapp }}
                        </div>
                    </div>

                </div>

                <div class="white-container content">
                    <h3 class="mt-2 mb-5"><i class="fal fa-hashtag fa-fw mr-3"></i>Posts & Photos</h3>
                    <div class="has-text-grey is-italic	"><i class="fal fa-comment-dots fa-fw mr-4 ml-1"></i>{{ participant.first_name }} hasn't posted anything yet.</div>
                </div>
            </div>
        </div>

    </section>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";
    import async from "async";
    import {client as http, url} from "../../http_client";
    import Participant from "../../components/Participant";

    export default {
        components: {Participant},
        props: ['id'],
        data: function() {
            return {
                participant: null,
                apiUrl: url
            };
        },
        computed: {
            ...mapGetters(['trip', 'registration']),
        },
        methods: {
            load: function(force) {
                async.series([
                    (cb) => {
                        if (this.registration.id) {
                            http.get('/app/trips/' + this.registration.trip_id + '/participants/' + this.id, {force}).then(response => {
                                this.participant = response.data;
                                cb();
                            });
                        }
                    }
                ], err => {

                });
            }
        },
        mounted() {
            this.load();
        }
    };
</script>

<style lang="scss" scoped>
    .profile-photo {
        margin: 0.05px 0.05px 1rem;
        background-color: white;
        border-radius: 0.3rem;
        padding: 1rem;
        cursor: pointer;
        text-align: center;
        font-size: 0.9rem;
        line-height: 1rem;

        i {
            font-size: 75px;
            color: #bebebe;
            display: block;
            margin: 0.8rem;
        }

        @media only screen and (max-width: 768px) {
            h4 {
                font-size: 1rem;
            }
        }

        .meta {
            font-size: 0.85rem;
            color: #4a4a4a;
        }

        .photo-frame {
            border: 1px solid #e7e7e7;
            display: inline-block;
            border-radius: 0.4rem;
            width: 150px;
            height: 150px;

            i {
                padding: 1.8rem 0;
            }
        }
    }
</style>
