<template>
    <section class="content" v-if="trip.itinerary_visable">
        <h3 class="mt-2 mb-5 ml-2 is-hidden-desktop"><i class="fal fa-calendar-alt fa-fw mr-3"></i>Trip Itinerary</h3>

        <div class="content mt-6 mb-6" v-if="!itinerary">
            <h5 class="has-text-grey"><i class="fal fa-tennis-ball fa-spin mr-3"></i>Loading your itinerary.  Please wait...</h5>
        </div>
        <div v-else>
            <itinerary-day :day="day" v-for="day in itinerary"/>
        </div>
    </section>
    <div class="content has-text-centered" v-else>
        <h3 class="pt-6 pb-6 has-text-grey"><i class="fal fa-message-captions mr-2"></i> This section is currently not available for this trip.</h3>
    </div>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";
    import async from "async";
    import {client as http} from "../../http_client";
    import DateLabel from "../../components/DateLabel";
    import ItineraryDay from "../../components/ItineraryDay";

    export default {
        components: {ItineraryDay, DateLabel},
        data: function() {
            return {
                itinerary: null
            };
        },
        computed: {
            ...mapGetters(['trip', 'registration']),
        },
        methods: {
            loadItinerary: function(force) {
                async.series([
                    (cb) => {
                        if (this.registration.id) {
                            http.get('/app/registrations/' + this.registration.id + '/itinerary', {force}).then(response => {
                                this.itinerary = response.data;
                                cb();
                            });
                        }
                    }
                ], err => {

                });
            }
        },
        watch: {
            registration: function() {
                this.loadItinerary(true);
            }
        },
        mounted() {
            this.loadItinerary();
        }
    };
</script>

<style lang="scss" scoped>
    .itinerary {
        margin-top: 0.1px;
    }
</style>
