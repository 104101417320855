<template>
    <div class="columns is-multiline document-container">
        <div class="column is-hidden-mobile is-narrow">
            <i class="fal has-text-primary fa-file-signature fa-2x pt-2 pb-2 pl-4 pr-3"></i>
        </div>
        <div class="column">
            <h4 class="pt-2"><i class="fal has-text-primary is-hidden-tablet fa-file-signature pt-2 pb-2 pl-4 pr-3"></i>{{document.subject}}</h4>
            <div class="mt-3">
                <div class="notes mb-4" v-if="document.information">{{document.information}}</div>
            </div>
        </div>
        <div class="column is-offset-1-desktop is-narrow has-text-right">
            <a :href="apiUrl + 'app/user/documents/' + document.file" target="_blank">
                <b-button type="is-primary" ><i class="fad fa-file-download mr-2"></i> Download/View</b-button>
            </a>

        </div>
    </div>
</template>

<script>

    import moment from "moment-timezone";
    import DateLabel from "./DateLabel";
    import {url} from "../http_client";

    export default {
        name: 'Document',
        components: {DateLabel},
        props: {
            document: {
                type: Object,
            }
        },
        data: function () {
            return {
                apiUrl: url
            };
        },
        computed: {
            duration: function () {
                if (this.document.arrive_at && this.document.depart_at) {
                    let min = moment.duration(moment(this.document.arrive_at).diff(this.document.depart_at)).asMinutes();
                    let hours = Math.floor(min / 60);
                    let minutes = min % 60;
                    if (hours > 0) {
                        return hours + ' hour' + (hours !== 1 ? 's' : '') + ', ' + minutes + ' minute' + (minutes !== 1 ? 's' : '');
                    } else {
                        return minutes + ' minutes';
                    }
                }
            },
            departLocal: function () {
                if (this.document.depart_at)
                    return moment.utc(this.document.depart_at).tz(this.document.depart_airport.tz_database).format('MMM Do, YYYY, h:mm A');
            },
            arriveLocal: function () {
                if (this.document.arrive_at)
                    return moment.utc(this.document.arrive_at).tz(this.document.arrive_airport.tz_database).format('MMM Do, YYYY, h:mm A');
            },
            departLocalTime: function () {
                if (this.document.depart_at)
                    return moment.utc(this.document.depart_at).tz(this.document.depart_airport.tz_database).format('h:mm A');
            },
            arriveLocalTime: function () {
                if (this.document.arrive_at)
                    return moment.utc(this.document.arrive_at).tz(this.document.arrive_airport.tz_database).format('h:mm A');
            }
        }
    };
</script>

<style lang="scss" scoped>
    .document-container {
        margin: 0.05px 0.05px 1rem;
        background-color: white;
        border-radius: 0.3rem;
        position: relative;
        padding: 0.05px;

        &.details {
            margin-bottom: 3rem;
        }

        @media only screen and (max-width: 768px) {
            h4 {
                font-size: 1rem;
            }
        }

        .meta {
            font-size: 0.85rem;
            color: #4a4a4a;
        }

        .notes {
            border: 1px solid #d9d9d9;
            border-radius: 8px;
            padding: 1rem 1.2rem;
            font-size: 0.9rem;
        }
    }

</style>
