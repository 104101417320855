<template>
    <section class="content" v-if="trip.participants_visible">
        <h3 class="mt-2 mb-5 ml-2 is-hidden-desktop"><i class="fal fa-users fa-fw mr-3"></i>Fellow Travelers</h3>
        <router-view/>
    </section>
    <div class="content has-text-centered" v-else>
        <h3 class="pt-6 pb-6 has-text-grey"><i class="fal fa-message-captions mr-2"></i> This section is currently not available for this trip.</h3>
    </div>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";
    import async from "async";
    import {client as http, url} from "../../http_client";
    import Participant from "../../components/Participant";

    export default {
        components: {Participant},
        data: function() {
            return {
                participants: [],
                participant: null,
                apiUrl: url
            };
        },
        computed: {
            ...mapGetters(['trip', 'registration']),
        },
        methods: {
            loadItinerary: function(force) {
                async.series([
                    (cb) => {
                        if (this.registration.id) {
                            http.get('/app/trips/' + this.registration.trip_id + '/participants', {force}).then(response => {
                                this.participants = response.data;
                                cb();
                            });
                        }
                    }
                ], err => {

                });
            }
        },
        watch: {
            registration: function() {
                this.loadItinerary(true);
            }
        },
        mounted() {
            this.loadItinerary();
        }
    };
</script>

<style lang="scss" scoped>
    .profile-photo {
        margin: 0.05px 0.05px 1rem;
        background-color: white;
        border-radius: 0.3rem;
        padding: 1rem;
        cursor: pointer;
        text-align: center;
        font-size: 0.9rem;
        line-height: 1rem;

        i {
            font-size: 75px;
            color: #bebebe;
            display: block;
            margin: 0.8rem;
        }

        @media only screen and (max-width: 768px) {
            h4 {
                font-size: 1rem;
            }
        }

        .meta {
            font-size: 0.85rem;
            color: #4a4a4a;
        }

        .photo-frame {
            border: 1px solid #e7e7e7;
            display: inline-block;
            border-radius: 0.4rem;
            width: 150px;
            height: 150px;

            i {
                padding: 1.8rem 0;
            }
        }
    }
</style>
