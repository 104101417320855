<template>
    <section class="content">
        <h3 class="mt-2 mb-5 ml-2 is-hidden-desktop"><i class="fal fa-plane fa-fw mr-3"></i>Flights</h3>

        <div class="content mt-6 mb-6" v-if="!flights">
            <h5 class="has-text-grey"><i class="fal fa-tennis-ball fa-spin mr-3"></i>Loading your flights.  Please wait...</h5>
        </div>
        <div v-else>
            <flight :flight="flight" v-for="flight in flights" v-if="flights.length"></flight>
            <h5 class="has-text-grey" v-else><i class="fal fa-plane mr-3"></i>You currently have no flight information.  Please check back later.</h5>
        </div>
    </section>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";
    import async from "async";
    import {client as http} from "../../http_client";
    import Flight from "../../components/Flight";

    export default {
        components: {Flight},
        data: function() {
            return {
                flights: null
            };
        },
        computed: {
            ...mapGetters(['trip', 'registration']),
        },
        methods: {
            loadItinerary: function(force) {
                async.series([
                    (cb) => {
                        if (this.registration.id) {
                            http.get('/app/registrations/' + this.registration.id + '/flights', {force}).then(response => {
                                this.flights = response.data;
                                cb();
                            });
                        }
                    }
                ], err => {

                });
            }
        },
        watch: {
            registration: function() {
                this.loadItinerary(true);
            }
        },
        mounted() {
            this.loadItinerary();
        }
    };
</script>

<style lang="scss" scoped>

</style>
