import Vue from 'vue';
import Vuex from 'vuex';
import user from './modules/user';
import {axiosInstance, client as http} from "../http_client";

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {user},
    state: {
        trip: {},
        registration: {}
    },
    getters: {
        trip: state => state.trip,
        registration: state => state.registration
    },
    mutations: {
        setTrip(state, payload) {
            state.trip = payload;
        },
        setRegistration(state, payload) {
            state.registration = payload;
        },
    },
    actions: {
        loadTrip({commit}, id) {
            return new Promise((resolve, reject) => {
                http.get('/app/trips/' + id).then(response => {
                    commit('setTrip', response.data);
                    resolve(response.data);
                }, err => {
                    reject(err.response.data);
                });
            });
        },
        loadRegistration({commit}, id) {
            return new Promise((resolve, reject) => {
                http.get('/app/registrations/' + id).then(response => {
                    localStorage.setItem('registration', id);
                    commit('setRegistration', response.data);
                    resolve(response.data);
                }, err => {
                    reject(err.response.data);
                });
            });
        }
    }
});
