<template>
    <div class="date-container" :class="{'print': print}">
        <div class="day">{{ date | moment('DD') }}</div>
        <div class="month">{{ date | moment('MMM') }}</div>
    </div>
</template>

<script>
    export default {
        name: "DateLabel",
        props: {
            date: {
                required: true
            },
            print: {
                type: Boolean,
                default: false
            }
        }
    };
</script>

<style lang="scss" scoped>
    .date-container {
        padding: 1rem 1rem 0.5rem 1rem;
        background-color: #f4f4f4;
        border-radius: 0.3rem;
        text-align: center;
        display: inline-block;
        line-height: 1.4rem;

        &.print {
            padding: 0.5rem 0.5rem 0.2rem 0.5rem;

            border: 1px solid #5b5b5b;
            background-color: #ffffff;
        }

        .day {
            font-weight: bold;
            font-size: 1.8rem;

            //margin-bottom: 1.4rem;
        }

        .month {
            text-transform: uppercase;
            font-size: 1rem;
            margin-left: 0.1rem;
            color: #888888;
        }
    }
</style>
