<template>
    <div>
        <div class="is-hidden-desktop pt-2 pb-2 has-text-centered" v-if="deferredPrompt">
            <b-button @click="install" type="is-primary"><i class="far fa-mobile mr-3"></i>Install <strong>Tennis Ventures Navigator</strong>?</b-button>
        </div>
        <div id="login-frame">
            <div class="auth-card columns">
                <div class="column is-5 left-side has-text-centered">
                    <img src="@/assets/logo.png" alt="Tennis Ventures">
                </div>

                <div class="column is-7 right-side">
                    <h6 class="mb-0">
                        <b-icon pack="far" icon="compass" class="compass-logo"></b-icon>
                        <strong class="ml-1">Tennis Ventures</strong> Navigator
                    </h6>
                    <div class="mb-5 has-text-grey login-text">Please login below to continue.</div>

                    <b-field>
                        <b-input placeholder="Email Address" type="email" icon="envelope" v-model="email" v-on:keyup.native.enter="formSubmit"></b-input>
                    </b-field>

                    <b-field>
                        <b-input placeholder="Password" type="password" icon="key" v-model="password" v-on:keyup.native.enter="formSubmit"></b-input>
                    </b-field>

                    <b-button type="is-primary" icon-right="sign-in-alt" class="mt-4 pr-5" :disabled="processing || !email || !password" @click="formSubmit">Login</b-button>

                    <div class="mt-6 has-text-right has-text-grey-light" @click="refresh">Navigator v{{version}}</div>

                </div>
            </div>
        </div>
        <notifications group="notify" />
    </div>
</template>

<script>
    import {mapActions} from 'vuex';
    import pkg from '../../package.json';

    export default {
        name: 'Login',
        data: function () {
            return {
                email: '',
                password: '',
                processing: false,
                deferredPrompt: false,
                version: pkg.version
            };
        },
        methods: {
            ...mapActions(['login']),
            formSubmit() {
                if (this.email && this.password) {
                    this.processing = true;
                    this.login({email: this.email, password: this.password}).then(response => {
                        this.$router.push('/app');
                    }).catch(error => {
                        console.log('login error');
                        console.error(error);

                        this.$notify({
                            group: 'notify',
                            type: 'error',
                            title: 'Login Failed',
                            text: (error.text) ? error.text : error.error.text
                        });
                        setTimeout(() => {
                            this.processing = false;
                        }, 3500);
                    });
                }
            },
            async dismiss() {
                this.deferredPrompt = null;
            },
            async install() {
                this.deferredPrompt.prompt();
            },
            refresh() {
                window.location = 'index.html?cache=' + Date.now();
            }
        },
        mounted() {
            if (localStorage.getItem('token')) {
                this.$router.push('/app');
            }
        },
        created() {
            window.addEventListener("beforeinstallprompt", e => {
                e.preventDefault();
                // Stash the event so it can be triggered later.
                this.deferredPrompt = e;
            });

            window.addEventListener("appinstalled", () => {
                this.deferredPrompt = null;
            });
        }
    };
</script>

<style lang="scss" scoped>
    #login-frame {
        display: flex;
        min-height: 100vh;
        align-items: center;
        justify-content: center;

        background: #252722 radial-gradient(#32322e, #252722);

        .auth-card {
            margin: 2rem;
            border-radius: 5px;
            box-shadow: 5px 5px 30px 0 rgba(0, 0, 0, 0.3);

            color: #242624;

            .left-side {
                image-rendering: -webkit-optimize-contrast;

                background-color: #ecece9;
                padding: 3rem 2rem 3rem 2.5rem;
                border-radius: 5px 0 0 5px;

                color: #d9e3ea;

                img {
                    max-width: 80%;
                    @media only screen and (max-width: 768px) {
                        max-width: 40%;
                        margin: 0.8rem 0;
                    }
                }

                @media only screen and (max-width: 768px) {
                    padding: 0.5rem;
                    border-radius: 5px 5px 0 0;
                }
            }

            .right-side {
                background-color: #FFFFFF;
                padding: 4.3rem 3rem 0 3rem;
                border-radius: 0 5px 5px 0;

                font-size: 0.85rem;

                .login-text {
                    padding-left: 3rem;

                    @media only screen and (max-width: 768px) {
                        padding-left: 0;
                    }
                }

                h6 {
                    font-size: 1.8rem;
                    color: #555;

                    .compass-logo {
                        color: #91b03e !important;
                        font-size: 2.8rem;
                        vertical-align: middle;
                        margin-right: 1rem;

                        @media only screen and (max-width: 768px) {
                            font-size: 2rem;
                            margin-right: 0.5rem;
                        }
                    }

                    @media only screen and (max-width: 768px) {
                        font-size: 1.3rem;
                        margin-bottom: 1rem !important;
                    }
                }

                @media only screen and (max-width: 768px) {
                    border-radius: 0 0 5px 5px;
                    padding: 2rem 1.5rem;
                }
            }

            @media only screen and (max-width: 768px) {
                margin: 0.5rem;

            }
        }
    }
</style>
