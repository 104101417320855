<template>
    <div class="content">
        <h3 class="mt-2 mb-5 ml-2 is-hidden-desktop"><i class="fal fa-user-cog fa-fw mr-3"></i>Account Settings</h3>

        <div class="content mt-6 mb-6" v-if="!user">
            <h5 class="has-text-grey"><i class="fal fa-tennis-ball fa-spin mr-3"></i>Loading your account. Please wait...</h5>
        </div>

        <div v-else>
            <div class="white-container content">
                <h6><i class="far fa-user fa-fw mr-2"></i>Personal Information</h6>

                <div class="columns">
                    <div class="column">
                        <b-field label="First Name" label-position="inside">
                            <b-input v-model="user.first_name"/>
                        </b-field>
                    </div>
                    <div class="column">
                        <b-field label="Middle Name" label-position="inside">
                            <b-input v-model="user.middle_name"/>
                        </b-field>
                    </div>
                    <div class="column">
                        <b-field label="Last Name" label-position="inside">
                            <b-input v-model="user.last_name"/>
                        </b-field>
                    </div>
                </div>

                <div class="columns">
                    <div class="column">
                        <b-field label="Preferred Name" label-position="inside">
                            <b-input v-model="user.preferred_name"/>
                        </b-field>
                    </div>
                    <div class="column">
                        <b-field label="Gender" label-position="inside">
                            <b-select v-model="user.gender" expanded>
                                <option value="Male">Male</option>
                                <option value="Female">Female</option>
                            </b-select>
                        </b-field>
                    </div>

                    <div class="column">
                        <b-field label="Date of Birth" label-position="inside">
                            <b-input type="date" v-model="user.dob"/>
                        </b-field>
                    </div>
                </div>

                <div class="columns">
                    <div class="column is-4">
                        <b-field label="Citizenship" label-position="inside">
                            <b-input v-model="user.citizen"/>
                        </b-field>
                    </div>
                </div>


            </div>

            <div class="white-container content">
                <h6><i class="far fa-align-left fa-fw mr-2"></i>Other Information</h6>

                <div class="columns">
                    <div class="column">
                        <b-field label="Dietary Requirements" label-position="inside">
                            <b-input v-model="user.dietary_requirements"/>
                        </b-field>
                    </div>

                </div>
                <div class="columns">
                    <div class="column">
                        <b-field label="T-Shirt Size" label-position="inside">
                            <b-select expanded v-model="user.shirt_size">
                                <option value="XS">XS</option>
                                <option value="S">S</option>
                                <option value="M">M</option>
                                <option value="L">L</option>
                                <option value="XL">XL</option>
                                <option value="XXL">XXL</option>
                            </b-select>
                        </b-field>
                    </div>
                </div>
                <div class="columns">
                    <div class="column">
                        <b-field label="NTRP Tennis Rating" label-position="inside">
                            <b-select expanded v-model="user.tennis_rating">
                                <option value="0">No Rating</option>
                                <option value="2.5">2.5</option>
                                <option value="3.0">3.0</option>
                                <option value="3.5">3.5</option>
                                <option value="4.0">4.0</option>
                                <option value="4.5">4.5</option>
                                <option value="5.0">5.0</option>
                                <option value="5.5">5.5</option>
                                <option value="6.0">6.0</option>
                            </b-select>
                        </b-field>
                    </div>
                </div>
                <div class="columns">
                    <div class="column">
                        <b-field label="Tennis Level" label-position="inside">
                            <b-select expanded v-model="user.tennis_level">
                                <option value="None">I Don't Plan on Playing</option>
                                <option value="Beginner">Beginner</option>
                                <option value="Intermediate">Intermediate</option>
                                <option value="Advanced">Advanced</option>
                            </b-select>
                        </b-field>
                    </div>
                </div>
            </div>

            <div class="white-container content">
                <h6><i class="far fa-address-card fa-fw mr-2"></i>Contact Information</h6>

                <div class="columns">
                    <div class="column is-8">
                        <b-field label="Address (Street & House Number)" label-position="inside">
                            <b-input v-model="user.address"/>
                        </b-field>
                    </div>
                    <div class="column">
                        <b-field label="Address 2 (Apartment, suite, etc.)" label-position="inside">
                            <b-input v-model="user.address_2"/>
                        </b-field>
                    </div>
                </div>

                <div class="columns">
                    <div class="column is-3">
                        <b-field label="City" label-position="inside">
                            <b-input v-model="user.city"/>
                        </b-field>
                    </div>
                    <div class="column is-3">
                        <b-field label="State" label-position="inside">
                            <b-select expanded v-model="user.state">
                                <option value="NA">- Not Applicable -</option>
                                <option value="AL">Alabama</option>
                                <option value="AK">Alaska</option>
                                <option value="AZ">Arizona</option>
                                <option value="AR">Arkansas</option>
                                <option value="CA">California</option>
                                <option value="CO">Colorado</option>
                                <option value="CT">Connecticut</option>
                                <option value="DE">Delaware</option>
                                <option value="DC">Dist of Columbia</option>
                                <option value="FL">Florida</option>
                                <option value="GA">Georgia</option>
                                <option value="HI">Hawaii</option>
                                <option value="ID">Idaho</option>
                                <option value="IL">Illinois</option>
                                <option value="IN">Indiana</option>
                                <option value="IA">Iowa</option>
                                <option value="KS">Kansas</option>
                                <option value="KY">Kentucky</option>
                                <option value="LA">Louisiana</option>
                                <option value="ME">Maine</option>
                                <option value="MD">Maryland</option>
                                <option value="MA">Massachusetts</option>
                                <option value="MI">Michigan</option>
                                <option value="MN">Minnesota</option>
                                <option value="MS">Mississippi</option>
                                <option value="MO">Missouri</option>
                                <option value="MT">Montana</option>
                                <option value="NE">Nebraska</option>
                                <option value="NV">Nevada</option>
                                <option value="NH">New Hampshire</option>
                                <option value="NJ">New Jersey</option>
                                <option value="NM">New Mexico</option>
                                <option value="NY">New York</option>
                                <option value="NC">North Carolina</option>
                                <option value="ND">North Dakota</option>
                                <option value="OH">Ohio</option>
                                <option value="OK">Oklahoma</option>
                                <option value="OR">Oregon</option>
                                <option value="PA">Pennsylvania</option>
                                <option value="RI">Rhode Island</option>
                                <option value="SC">South Carolina</option>
                                <option value="SD">South Dakota</option>
                                <option value="TN">Tennessee</option>
                                <option value="TX">Texas</option>
                                <option value="UT">Utah</option>
                                <option value="VT">Vermont</option>
                                <option value="VA">Virginia</option>
                                <option value="WA">Washington</option>
                                <option value="WV">West Virginia</option>
                                <option value="WI">Wisconsin</option>
                                <option value="WY">Wyoming</option>
                            </b-select>
                        </b-field>
                    </div>
                    <div class="column is-2">
                        <b-field label="Zip" label-position="inside">
                            <b-input v-model="user.zip"/>
                        </b-field>
                    </div>
                    <div class="column">
                        <b-field label="Country" label-position="inside">
                            <b-input v-model="user.country"/>
                        </b-field>
                    </div>
                </div>
                <div class="columns">
                    <div class="column">
                        <b-field label="Cell Phone" label-position="inside">
                            <b-input v-model="user.phone" v-mask="'+############'"/>
                        </b-field>
                    </div>
                    <div class="column">
                        <b-field label="Phone" label-position="inside">
                            <b-input v-model="user.phone_2" v-mask="'+############'"/>
                        </b-field>
                    </div>
                </div>
            </div>

            <div class="white-container content">
                <h6><i class="far fa-lock-keyhole fa-fw mr-2"></i>Login Credentials</h6>

                <div class="columns">
                    <div class="column">
                        <b-field label="E-Mail Address" label-position="inside">
                            <b-input type="email" v-model="user.email"/>
                        </b-field>
                    </div>
                    <div class="column">
                        <b-field label="Password" label-position="inside">
                            <b-input placeholder="Set to Change" v-model="user.password"></b-input>
                        </b-field>
                    </div>
                </div>
            </div>
        </div>

        <div class="mt-6 has-text-right">
            <b-button type="is-primary" @click="save" :disabled="processing"><i class="fas fa-check mr-2"></i>Save Changes</b-button>
        </div>

    </div>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";
    import async from "async";
    import {client as http} from "../../http_client";

    export default {
        data: function() {
            return {
                user: null,
                processing: false
            };
        },
        computed: {
            ...mapGetters(['trip', 'registration']),
        },
        methods: {
            loadUser: function(force) {
                http.get('/app/user/', {force}).then(response => {
                    this.user = response.data;
                });
            },
            save: function() {
                if (!this.processing) {
                    this.processing = true;
                    if (this.user.password && this.user.password === '') delete this.user.password;
                    http.put('/app/user', this.user, {force: true}).then(response => {
                        this.$notify({
                            group: 'notify',
                            type: 'success',
                            title: 'Save Complete',
                            text: 'Your account settings have been saved.'
                        });
                        setTimeout(() => {
                            this.processing = false;
                        }, 3500);
                    });
                }
            }
        },
        mounted() {
            this.loadUser(true);
        }
    };
</script>

<style lang="scss" scoped>

</style>
