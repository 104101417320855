<template>
    <div class="columns">
        <div class="column is-one-quarter-fullhd is-one-third-widescreen is-one-third-tablet is-hidden-touch">
            <ul class="sub-nav-menu">
                <router-link :to="{name: 'Itinerary'}">
                    <li><i class="far fa-calendar-alt fa-fw mr-3"></i>Itinerary</li>
                </router-link>
                <router-link :to="{name: 'Flights'}">
                    <li><i class="far fa-plane fa-fw mr-3"></i>Flights</li>
                </router-link>
<!--                <router-link :to="{name: 'Info'}">-->
<!--                    <li><i class="far fa-messages fa-fw mr-3"></i>Info & Answers</li>-->
<!--                </router-link>-->

                <router-link :to="{name: 'Travel'}">
                    <li><i class="far fa-tasks fa-fw mr-3"></i>Travel Requirements</li>
                </router-link>
                <router-link :to="{name: 'Tournament'}">
                    <li><i class="far fa-trophy fa-fw mr-3"></i>Tournament</li>
                </router-link>
                <router-link :to="{name: 'Cities'}">
                    <li><i class="far fa-city fa-fw mr-3"></i>Cities</li>
                </router-link>
                <router-link :to="{name: 'Packing'}">
                    <li><i class="far fa-suitcase-rolling fa-fw mr-3"></i>Packing List</li>
                </router-link>
                <router-link :to="{name: 'FAQs'}">
                    <li><i class="far fa-question-square fa-fw mr-3"></i>FAQs</li>
                </router-link>
            </ul>
            <ul class="sub-nav-menu mt-4">
                <router-link :to="{name: 'Preferences'}">
                    <li><i class="far fa-tasks fa-fw mr-3"></i>Trip Preferences</li>
                </router-link>
                <router-link :to="{name: 'Documents'}">
                    <li><i class="far fa-file-signature fa-fw mr-3"></i>Tickets & Documents</li>
                </router-link>
            </ul>
            <ul class="sub-nav-menu mt-4">
                <a :href="'/#/print/travel/' + registration.id" target="_blank">
                    <li><i class="far fa-print fa-fw mr-3"></i>Print Travel Details</li>
                </a>
            </ul>
        </div>
        <div class="column is-offset-1-fullhd ">
            <router-view/>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from "vuex";

    export default {
        computed: {
            ...mapGetters(['trip', 'registration']),
        }
    };
</script>

<style lang="scss" scoped>

</style>
