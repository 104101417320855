<template>
    <div class="content has-text-centered">
        <h3 class="pt-6 pb-6 has-text-grey"><i class="fal fa-message-captions mr-2"></i> This section is currently not available for this trip.</h3>
    </div>
</template>

<script>
    export default {};
</script>

<style lang="scss" scoped>

</style>
