<template>
    <div class="columns">
        <div class="column is-one-quarter-fullhd is-one-third-widescreen is-one-third-tablet is-hidden-touch">
            <ul class="sub-nav-menu">
                <router-link :to="{name: 'Billing'}">
                    <li><i class="far fa-credit-card-front fa-fw mr-3"></i>Trip <span class="is-hidden-desktop-only">Summary </span>& Payments</li>
                </router-link>
                <router-link :to="{name: 'Preferences'}">
                    <li><i class="far fa-tasks fa-fw mr-3"></i>Trip Preferences</li>
                </router-link>
                <router-link :to="{name: 'Documents'}">
                    <li><i class="far fa-file-signature fa-fw mr-3"></i>Tickets & Documents</li>
                </router-link>
                <router-link :to="{name: 'Profile'}">
                    <li><i class="far fa-address-card fa-fw mr-3"></i>Profile & Photo</li>
                </router-link>
                <router-link :to="{name: 'Settings'}">
                    <li><i class="far fa-user-cog fa-fw mr-3"></i>Account Settings</li>
                </router-link>
            </ul>
        </div>
        <div class="column is-offset-1-fullhd ">
            <router-view/>
        </div>
    </div>
</template>

<script>
    export default {

    };
</script>

<style lang="scss" scoped>

</style>
