import Vue from 'vue';
import VueRouter from 'vue-router';
import Login from '../views/Login.vue';
import Share from '../views/Share.vue';
import App from '../views/App.vue';
import Today from '../views/today/Today.vue';
import Trip from '../views/trip/Trip.vue';
import Itinerary from '../views/trip/Itinerary.vue';
import Flights from '../views/trip/Flights.vue';
import Packing from '../views/trip/Packing.vue';
import FAQs from '../views/trip/FAQs.vue';
import Experience from '../views/experience/Experience.vue';
import Cities from '../views/trip/Cities.vue';
import Tournament from '../views/trip/Tournament.vue';
import Travel from '../views/trip/Travel.vue';
import Travelers from '../views/travelers/Travelers.vue';
import AllTravelers from '../views/travelers/All.vue';
import Traveler from '../views/travelers/Traveler.vue';
import Tools from '../views/tools/Tools.vue';
import Settings from '../views/tools/Settings.vue';
import Profile from '../views/tools/Profile.vue';
import Preferences from '../views/tools/Preferences.vue';
import Documents from '../views/tools/Documents.vue';
import Billing from '../views/tools/Billing.vue';
import Trips from '../views/Trips.vue';
import Print from '../views/print/Print.vue';
import TravelDetails from '../views/print/TravelDetails.vue';

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'Login',
        component: Login
    },
    {
        path: '/share/:keyToken/:returnTo',
        name: 'Share',
        component: Share,
        props: true
    },
    {
        path: '/app',
        name: 'App',
        component: App,
        redirect: '/app/today',
        children: [
            {
                path: 'trips',
                name: 'Trips',
                component: Trips
            },
            {
                path: 'today',
                name: 'Today',
                component: Today
            },
            {
                path: 'trip',
                name: 'Trip',
                component: Trip,
                redirect: 'trip/itinerary',
                children: [
                    {
                        path: 'itinerary',
                        name: 'Itinerary',
                        component: Itinerary
                    },
                    {
                        path: 'flights',
                        name: 'Flights',
                        component: Flights
                    },
                    {
                        path: 'travel',
                        name: 'Travel',
                        component: Travel
                    },
                    {
                        path: 'tournament',
                        name: 'Tournament',
                        component: Tournament
                    },
                    {
                        path: 'cities',
                        name: 'Cities',
                        component: Cities
                    },
                    {
                        path: 'packing',
                        name: 'Packing',
                        component: Packing
                    },
                    {
                        path: 'faqs',
                        name: 'FAQs',
                        component: FAQs
                    }
                ]
            },
            {
                path: 'experience',
                name: 'Experience',
                component: Experience
            },
            {
                path: 'travelers',
                name: 'Travelers',
                component: Travelers,
                redirect: 'travelers/all',
                children: [
                    {
                        path: 'all',
                        name: 'AllTravelers',
                        component: AllTravelers
                    },
                    {
                        path: 'traveler/:id',
                        name: 'Traveler',
                        component: Traveler,
                        props: true
                    }
                ]
            },
            {
                path: 'tools',
                name: 'Tools',
                component: Tools,
                redirect: 'tools/billing',
                children: [
                    {
                        path: 'profile',
                        name: 'Profile',
                        component: Profile
                    },
                    {
                        path: 'settings',
                        name: 'Settings',
                        component: Settings
                    },
                    {
                        path: 'preferences',
                        name: 'Preferences',
                        component: Preferences
                    },
                    {
                        path: 'documents',
                        name: 'Documents',
                        component: Documents
                    },
                    {
                        path: 'billing',
                        name: 'Billing',
                        component: Billing
                    }
                ]
            },
        ]
    },
    {
        path: '/print',
        name: 'Print',
        component: Print,
        children: [
            {
                path: 'travel/:id',
                name: 'TravelDetails',
                component: TravelDetails,
                props: true
            }
        ]
    }
];

const router = new VueRouter({
    routes
});

export default router;
